<template>
  <main class="container-fluid">
    <b-card class="af-card pb-5">
      <b-tabs content-class="mt-3">
        <b-tab title="Verified Users" title-link-class="px-0 mr-3" active>
          <Verified :users="users" />
        </b-tab>
        <b-tab title="Un-verified Users" title-link-class="px-0 mr-3">
          <Unverified :users="users" />
        </b-tab>
      </b-tabs>
    </b-card>
  </main>
</template>

<script>
import Verified from '../components/verification/Verified.vue'
import Unverified from '../components/verification/Unverified.vue'

export default {
  metaInfo: {
    title: 'Verification',
    titleTemplate: '%s | AirtimeFlip'
  },
  components: {
    Verified,
    Unverified
  },
  data () {
    return {
      users: []
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
